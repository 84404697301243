const data = {
  recipientGroup :[
    { id: 1, group_name:'Seluruh Kepala Bagian', members:'KOMISARIS DAN DIREKSI, SEKRETARIAT PERUSAHAAN, SATUAN PENGAWASAN INTERN, TANAMAN, TEKNIK DAN PENGOLAHAN, KEUANGAN DAN AKUNTANSI, PERENCANAAN DAN SUSTAINABILITY, SDM, PENGADAAN DAN UMUM, OPTIMALISASI ANAK PERUSAHAAN DAN ASET'},
    { id: 2, group_name:'Seluruh General Manager Distrik', members:'GENERAL MANAGER DISTRIK I, GENERAL MANAGER DISTRIK II, GENERAL MANAGER DISTRIK III, GENERAL MANAGER DISTRIK IV'},
    { id: 3, group_name:'Seluruh Manajer Kebun/PKS Distrik 1', members:' UNIT USAHA BAH JAMBI,  UNIT USAHA BALIMBINGAN,  UNIT USAHA MARIHAT,  UNIT USAHA TONDUHAN,  UNIT USAHA PASIR MANDOGE,  UNIT USAHA DOLOK SINUMBAH UNIT USAHA SEI KOPAS,  UNIT USAHA MARJANDI,  UNIT USAHA BAH BIRUNG ULU'},
    { id: 4, group_name:'Seluruh Manajer Kebun/PKS Distrik 2', members:'UNIT USAHA BUKIT LIMA, UNIT USAHA DOLOK ILIR, UNIT USAHA LARAS, UNIT USAHA GUNUNG BAYU, UNIT USAHA MAYANG, UNIT USAHA TANAH ITAM ULU' },
    { id: 5, group_name:'Seluruh Manajer Kebun/PKS Distrik 3', members:'UNIT USAHA ADOLINA, UNIT USAHA PABATU, UNIT USAHA PADANG MATINGGI, UNIT USAHA SAWIT LANGKAT, UNIT USAHA TINJOWAN, UNIT USAHA TIMUR, UNIT USAHA BATANG LAPING' },
    { id: 6, group_name:'Seluruh Manajer Kebun/PKS Distrik 4', members:'UNIT USAHA AJAMU, UNIT USAHA BERANGIR, UNIT USAHA MERANTI PAHAM, UNIT USAHA PANAI JAYA, UNIT USAHA PULU RAJA, UNIT USAHA SOSA, UNIT USAHA AIR BATU' },
    { id: 7, group_name:'Seluruh Manajer Kebun/PKS', members:'SELURUH ANGGOTA GROUP NOMOR 3,4,5,6 DI ATAS' },
    { id: 8, group_name:'Seluruh Manajer Kebun', members:'UNIT USAHA TONDUHAN, UNIT USAHA TINJOWAN, UNIT USAHA TIMUR, UNIT USAHA TANAH ITAM ULU, UNIT USAHA SOSA, UNIT USAHA SEI KOPAS, UNIT USAHA SAWIT LANGKAT, UNIT USAHA PULU RAJA, UNIT USAHA PLASMA MADINA, UNIT USAHA GUNUNG BAYU, UNIT USAHA DOLOK SINUMBAH, UNIT USAHA DOLOK ILIR, UNIT USAHA BUKIT LIMA, UNIT USAHA BERANGIR, UNIT USAHA BATANG LAPING, UNIT USAHA BALIMBINGAN, UNIT USAHA BAH JAMBI, UNIT USAHA BAH BIRONG ULU, UNIT USAHA AJAMU, UNIT USAHA AIR BATU, UNIT USAHA ADOLINA, UNIT MERANTI PAHAM' },
    { id: 9, group_name:'Seluruh Manajer Pabrik', members:'UNIT USAHA PKS TINJOWAN, UNIT USAHA PKS TIMUR, UNIT USAHA PKS PASIR MANDOGE, UNIT USAHA PKS PABATU, UNIT USAHA PKS GUNUNG BAYU, UNIT USAHA PKS DOLOK ILIR, UNIT USAHA PKS BERANGIR, UNIT USAHA PKS BAH JAMBI, UNIT USAHA PKS AJAMU, UNIT USAHA PKS ADOLINA' },
    { id: 10, group_name:'Seluruh Manajer Teh', members:'UNIT USAHA TOBASARI, UNIT USAHA BAH BUTONG' },
  ]
}
export default data;