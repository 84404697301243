<template>
    <v-dialog v-model="helpGroupRecipientDialog" persistent max-width="1360" style='z-index:9999;'>
      <v-card>
        <v-card-title>{{ $t("card.distribution-list") }}</v-card-title>
        <v-card-text>
          <p v-html="$t('text.group-recipient-select-desc')"></p>
          <v-simple-table fixed-header height="300px">
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">No</th>
                  <th
                    width="23%"
                    class="text-left"
                    v-html="$t('text.group-name')"
                  ></th>
                  <th class="text-left" v-html="$t('text.group-member')"></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in recipient" :key="item.id">
                  <td class="text-right">{{ item.id }}.</td>
                  <td v-html="item.group_name"></td>
                  <td v-html="item.members"></td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card-text>
        <v-card-actions class="d-flex justify-end">
          <v-btn class="mb-1" color="primary" text @click="helpGroupRecipientDialog = false">
            {{ $t("button.i-understand") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
</template>
<script>
import tableData from "@/constants/recipientgroup";
export default {
  name: "help-group-recipient-modal",
  data: () => ({
    recipient: tableData.recipientGroup,
  }),
  computed: {
    helpGroupRecipientDialog: {
      get() {
        return this.$store.state.helpGroupRecipientDialog;
      },
      set(val) {
        this.$store.commit("SET_HELP_GROUP_RECIPIENT_DIALOG", val);
      },
    },
  },
  watch: {
    helpGroupRecipientDialog() {
      console.info("modal helper is show");
    },
  },
};
</script>
<style lang="scss">
#help-group-recipient-dialog {
  .v-dialog__content.v-dialog__content--active {
    z-index: 999999991 !important;
  }
}
</style>