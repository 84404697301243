<template>
  <v-footer app class="footerpart" inset>
    <v-col cols="12">
      <span>© {{ new Date().getFullYear() }} — {{coopName}}</span>
    </v-col>
  </v-footer>
</template>
<script>
import { companyName } from "@/constants/config";
export default {
  name: "Footer",
  data() {
    return {
      coopName: companyName,
    };
  },
};
</script>
<style lang="scss">
.v-application .footerpart {
  border-top: 1px solid rgba(0, 0, 0, 0.1) !important;
  height: 3em;
  padding: 0 0 0 1em;
}
</style>