<template>
  <div>
    <div v-for="link in links" :key="link.row_num">
      <div class="main__menu-separator" v-if="link.slug === '_separator'"></div>
      <v-list-item
      v-if="!link.subLinks && link.slug != '_separator'"
      :to="link.route_path"
      :active-class="`success white--text`"
      class="v-list-item"
      :class="link.customClass"
      >
      <v-list-item-icon v-if="link.icon_name">
        <mdicon :name="link.icon_name"/>
        </v-list-item-icon>
        <v-list-item-title class="sidebar-item--title" v-text="$t(link.title)" />
        <v-badge
          v-if="link.slug == 'inbox' && inboxCount > 0"
          class="app__sidebar-badge"
          color="red"
          :content="inboxCount"
        ></v-badge>
        <v-badge
          v-if="link.slug == 'incoming' && inboxExtCount > 0"
          class="app__sidebar-badge"
          color="red"
          :content="inboxExtCount"
        ></v-badge>
        <v-badge
          v-if="link.slug == 'permit_list' && permitCount > 0"
          class="app__sidebar-badge"
          color="red"
          :content="permitCount"
        ></v-badge>
        <v-badge
          v-if="
            link.slug == 'revision_list' && revisionCount > 0
          "
          class="app__sidebar-badge"
          color="red"
          :content="revisionCount"
        ></v-badge>
        <v-badge
          v-if="
            link.slug == 'draft_approval' && revisionCount > 0
          "
          class="app__sidebar-badge"
          color="red"
          :content="revisionCount"
        ></v-badge>
        <v-badge
          v-if="
            link.slug == 'disposition' && dispositionCount > 0
          "
          class="app__sidebar-badge"
          color="red"
          :content="dispositionCount"
        ></v-badge>
        <v-badge
          v-if="
            link.slug == 'copy_sppd' && sppdCount > 0
          "
          class="app__sidebar-badge"
          color="red"
          :content="sppdCount"
        ></v-badge>
      </v-list-item>
      <v-list-group
        v-if="link.subLinks && link.slug != 'separator'"
        :key="link.row_num"
        no-action
        :prepend-icon="link.icon"
        :value="subIsActive(link.parent)"
      >
        <template v-slot:activator>
          <v-list-item-title>{{ $t(link.title) }}</v-list-item-title>
        </template>

        <v-list-item
          v-for="sublink in link.subLinks"
          :to="sublink.route_path"
          :key="sublink.row_num"
          :active-class="`success white--text`"
          :class="sublink.customClass"
        >
          <v-list-item-title>{{ $t(sublink.title) }}</v-list-item-title>
          <v-list-item-icon>
            <v-icon>{{ sublink.icon }}</v-icon>
          </v-list-item-icon>
        </v-list-item>
      </v-list-group>
    </div>
  </div>
</template>
<script lang="ts">
import Vue from "vue";
export default Vue.extend({
  name: "siebar-item",
  props: {
    links: { type: [Object, Array] },
    unreadCount: { type: Object },
  },
  computed: {
    inboxCount() {
      if (!this.unreadCount || !this.unreadCount.inbox) return 0;
      return parseInt(this.unreadCount.inbox);
    },
    inboxExtCount() {
      if (!this.unreadCount || !this.unreadCount.incoming) return 0;
      return parseInt(this.unreadCount.incoming);
    },
    sppdCount() {
      if (!this.unreadCount || !this.unreadCount.sppd) return 0;
      return parseInt(this.unreadCount.sppd);
    },
    permitCount() {
      if (!this.unreadCount || !this.unreadCount.unreadIzinPrinsip) return 0;
      return parseInt(this.unreadCount.unreadIzinPrinsip);
    },
    revisionCount() {
      if (!this.unreadCount || !this.unreadCount.myRevision) return 0;
      return parseInt(this.unreadCount.myRevision);
    },
    dispositionCount() {
      if (!this.unreadCount || !this.unreadCount.myDisposition) return 0;
      return parseInt(this.unreadCount.myDisposition);
    },
  },
  methods: {
    subIsActive(input) {
      const paths = Array.isArray(input) ? input : [input];
      return paths.some((path) => {
        return this.$route.path.indexOf(path) === 0; // current path starts with this path string
      });
    },
  },
});
</script>
<style lang="css">
.sidebar-item--title {
  font-size: 1rem !important;
}
</style>